import React, { createContext, useState } from 'react';

export const PopupManagerContext = createContext();

export function PopupProvider({ children }) {

  const [dataPopup, setDisplayPopup] = useState({ 
    display: false,
    data: {
      background: '#fff'
    }
  });

  React.useEffect(() => {

    if( dataPopup.display ) {
      console.log( dataPopup )
      window.history.pushState({}, null, `/funds/${dataPopup.data.title}-${dataPopup.data.isin}`);
    }
    
    return () => {
      
    };

  }, [dataPopup])

  return (
    <PopupManagerContext.Provider value={
        [dataPopup, setDisplayPopup]
    }>
      {children}
    </PopupManagerContext.Provider>
  );
}