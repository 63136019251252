import { createSelector } from 'reselect';

export const homeSelector = state => state.home;

export const HomePalmaresLoadingSelector = createSelector(
    [ homeSelector ],
    home => home.isLoading
)

export const HomePalmaresSelector = createSelector(
    [homeSelector],
    home => home.palmares
)
