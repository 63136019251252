import React from 'react'
import label from '../../assets/images/label.png'
import { AppTextContext } from '../../context/AppTextProvider';

function IsrContent(props) {
    const appText = React.useContext(AppTextContext)
    return (
        <>
            { props.contentProps.no_content_title && !props.contentProps.no_content_title  && <h2>Plus d’infos sur le label ISR</h2> }

            <span dangerouslySetInnerHTML={ {__html:appText.fundDetails.label_isr_popup_text} } />
        </>
    )
}

export default IsrContent
