import React from 'react'
import cx from 'classnames/bind'

function ToolTipComponent(props) {

    const [show, setShow] = React.useState(false)
    const [placement, setPlacement] = React.useState('left')

    const ref = React.useRef()

    React.useEffect(() => {

        if( props.visible === `tooltip-${props.index}` ) {
            setShow(props.visible );
        }

        return () => {
            
        };

    }, [props.visible])

    React.useEffect(() => {

        const containerW = document.querySelector('.content-popup');

        if( ref.current.offsetLeft - containerW.offsetLeft  > ( containerW.clientWidth / 2) )
        {
            setPlacement('right')
        }

        return () => {
            
        };

    }, [])

    function convertHex(hex,opacity){
        if ( hex ) {
            let hexFinal = hex.replace('#','');
            const r = parseInt(hexFinal.substring(0,2), 16);
            const g = parseInt(hexFinal.substring(2,4), 16);
            const b = parseInt(hexFinal.substring(4,6), 16);
        
            const result = 'rgba('+r+','+g+','+b+','+opacity/100+')';
            return result;
        } 

        return '';
       

    }

    function renderIncon( icon ) {
        switch (icon) {
            case 'markup':
                return(
                    <svg className="ttip"  width="9" height="13" viewBox="0 0 9 13" fill={props.background} xmlns="http://www.w3.org/2000/svg">
                        <path className="ttip" d="M4.34 0.5C5.11867 0.5 5.78533 0.622667 6.34 0.868C6.89467 1.11333 7.31067 1.43867 7.588 1.844C7.876 2.24933 8.02 2.69733 8.02 3.188C8.02 3.62533 7.94 3.99867 7.78 4.308C7.63067 4.61733 7.444 4.87333 7.22 5.076C6.996 5.268 6.70267 5.48667 6.34 5.732C5.90267 6.02 5.57733 6.28667 5.364 6.532C5.15067 6.76667 5.044 7.076 5.044 7.46V7.796H2.74V7.38C2.74 6.87867 2.82 6.45733 2.98 6.116C3.14 5.77467 3.332 5.50267 3.556 5.3C3.78 5.08667 4.06267 4.86267 4.404 4.628C4.74533 4.39333 4.99067 4.196 5.14 4.036C5.3 3.86533 5.38 3.65733 5.38 3.412C5.38 3.124 5.26267 2.9 5.028 2.74C4.79333 2.58 4.484 2.5 4.1 2.5C3.73733 2.5 3.38533 2.58533 3.044 2.756C2.70267 2.916 2.388 3.156 2.1 3.476L0.5 2.228C1.48133 1.076 2.76133 0.5 4.34 0.5ZM3.924 9.252C4.31867 9.252 4.66 9.396 4.948 9.684C5.236 9.96133 5.38 10.2973 5.38 10.692C5.38 11.0867 5.236 11.428 4.948 11.716C4.67067 12.004 4.32933 12.148 3.924 12.148C3.52933 12.148 3.188 12.0093 2.9 11.732C2.62267 11.444 2.484 11.0973 2.484 10.692C2.484 10.2973 2.62267 9.96133 2.9 9.684C3.188 9.396 3.52933 9.252 3.924 9.252Z" fill={props.background}/>
                    </svg>
                )        
            default:
                return(
                    <svg className="ttip"  width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle opacity="0.15" cx="10" cy="10" r="10" fill={props.background}/>
                        <path className="ttip" d="M10.408 3C10.8453 3 11.2027 3.13867 11.48 3.416C11.7573 3.68267 11.896 4.01867 11.896 4.424C11.896 4.84 11.7573 5.18133 11.48 5.448C11.2027 5.71467 10.8453 5.848 10.408 5.848C9.97067 5.848 9.608 5.71467 9.32 5.448C9.04267 5.17067 8.904 4.82933 8.904 4.424C8.904 4.01867 9.04267 3.68267 9.32 3.416C9.608 3.13867 9.97067 3 10.408 3ZM11.928 14.216H14.104V15.944H7V14.216H9.4V9.176H7.08V7.448H11.928V14.216Z" fill={props.background}/>
                    </svg> 
                )
        }
    }


    return (
        <span 
            ref={ ref }
            id={ `tooltip-${props.index}` }
            onClick={ () => {  props.content ? props.OnTooltip(`tooltip-${props.index}`) : props.OnDisplay()  } }
            className={cx({
                'tooltip_canary' : true,
                'ttip' : props.index !== undefined,
                'right' : placement !== 'left',
                'show' : show === props.visible ? true : false
            })} 
            style={{
                background: convertHex(props.background, 35)
            }}
        >
            { renderIncon( props.icon ) }
            
            <div 
            style={ props.customStyle }
            className={ cx({
                'content_tooltip' : true,
                'show' : show === props.visible ? true : false,
            })}>
                { props.content }
            </div> 
        </span>
    )
}

export default ToolTipComponent
