import React from 'react'
import { AppTextContext } from '../../context/AppTextProvider';

function WhereThisNoteFrom(props) {
    const appText = React.useContext(AppTextContext)
    return (
        <>
            { props.contentProps.no_content_title && !props.contentProps.no_content_title  && <h2>D’où vient cette note ?</h2> }

            <span dangerouslySetInnerHTML={ {__html:appText.fundDetails.risk_indice_detail_popup_text} } />
        </>
    )
}

export default WhereThisNoteFrom
