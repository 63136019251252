import apiService from "../../conf/api.custom";

export const REQUEST_PALMARES = 'request palamares';
export const FETCH_PALMARES = 'fetch palamares';
export const FETCH_PALMARES_SUCCESS = 'fetch palamares success';
export const FETCH_PALMARES_ERROR= 'fetch palamares error';

export const REQUEST_PALIER = 'request palier';
export const FETCH_PALIER = 'fetch palier';
export const FETCH_PALIER_SUCCESS = 'fetch palier success';
export const FETCH_PALIER_ERROR= 'fetch palier error';

export const requestPalmares = () => ({
    type: REQUEST_PALMARES,
});

export const fetchPalmaresSucess = data => ({
    type: FETCH_PALMARES_SUCCESS,
    data
});

export const fetchPalmares = ( max ) => dispatch => {
    dispatch( requestPalmares() );
    return apiService.get(`/fonds/palmares&3&0`).then(
        response => dispatch( fetchPalmaresSucess(response.data) ),
        error => dispatch( fetchPalmaresError(error.response) )
    )
}

export const fetchPalmaresError = error => ({
    type: FETCH_PALMARES_ERROR,
    error
});

export const requestPalier = () => ({
    type: REQUEST_PALIER,
});

export const fetchPalierSucess = data => ({
    type: FETCH_PALIER_SUCCESS,
    data
});

export const fetchPalier = ( max ) => dispatch => {
    dispatch( requestPalier() );
    return apiService.get(`/fonds/paliers/`).then(
        response => dispatch( fetchPalierSucess(response.data) ),
        error => dispatch( fetchPalierError(error.response) )
    )
}

export const fetchPalierError = error => ({
    type: FETCH_PALIER_ERROR,
    error
});

