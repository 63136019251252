import React from "react";
import { AppTextContext } from "../../context/AppTextProvider";
import * as scroll from "react-scroll";
import triangleImg from "../../assets/images/triangle.svg";
import { Helmet } from "react-helmet";

function Faq() {
  const appText = React.useContext(AppTextContext);
  // const [dataModal, setDataModal]  = React.useContext( ModalInfoManagerContext )

  React.useEffect(() => {
    scroll.animateScroll.scrollToTop();

    return () => {};
  }, []);

  return (
    <>
      <Helmet>
        <title>Questions fréquentes sur l'utilisation du Canary</title>
        <meta
          name="description"
          content="Vous avez besoin de précisions sur le fonctionnement et l'utilisation du Canary ? L'outil qui vous éclaire dans vos choix d'investissements"
        />
      </Helmet>
      <div className="account_template">
        <div className="page">
          <h1>
            {appText.footer.FAQ_titre}
            <img src={triangleImg} alt="triangle" />
          </h1>
          <div className="wrapper">
            <span
              dangerouslySetInnerHTML={{
                __html: appText.footer.FAQ_description
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Faq;
