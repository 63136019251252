import React, { useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import { routes } from './routes'
import { PrivateRoute } from './PrivateRoute'
import { NotFound } from '../components/not-found/NotFound'
import ScrollToTop from './ScrollToTop'
import { Header } from '../components'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Footer from '../components/shared/Footer'
// import UserConnecterMenu from '../components/shared/UserConnecterMenu';
// import { AuthContext } from '../context/AuthContext'
import StickySearch from '../components/shared/StickySearch'

const AppRouter = props => {
  // const context = useContext(AuthContext)

  useEffect(() => {
    return () => {}
  }, [])

  return (
    <ScrollToTop>
      <StickySearch />
      {!props.location.pathname.includes('fund') && <Header />}
      <Switch>
        {// Les routes sont à définir dans le fichier src/routing/route.jsx
        routes.map(r => {
          return r.private ? (
            <PrivateRoute
              key={r.path}
              component={r.component}
              path={r.path}
              exact
              user={props.app.user}
              {...r}
            />
          ) : (
            <Route
              key={r.path}
              render={props => <r.component {...props} {...r} />}
              path={r.path}
              exact={r.exact}
            />
          )
        })}
        {/* Si aucune route trouvé on affiche le composant NotFound */}
        <Route component={NotFound} />
      </Switch>
      <Footer />
      {/* {context.isAuth  && <UserConnecterMenu /> } */}
    </ScrollToTop>
  )
}

export default withRouter(connect(state => ({ app: state.app }))(AppRouter))
