import React from 'react'
import cx from 'classnames/bind'
function AccountLine(props) {
    return (
        <div className={ cx({
            account_line: true,
            edit_mode : props.editing
        })}  >
            { props.children }
        </div>
    )
}

export default AccountLine
