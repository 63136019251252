//import * as firebase from "firebase";
import base from "./base";
// import { toast } from "react-toastify";

export default class FirebaseAuth extends base {
  login = ({ actions, values, ...rest }, AuthContextClass) => {
    // try {
    //   firebase
    //     .auth()
    //     .signInWithEmailAndPassword(values.email, values.password)
    //     .then(
    //       auth => {
    //         actions.setSubmitting(false);
    //         AuthContextClass.setAuth(auth);
    //         localStorage.setItem("authUser", JSON.stringify(auth));
    //       },
    //       err => {
    //         toast.error("Problème de connexion");
    //         actions.setSubmitting(false);
    //       }
    //     );
    // } catch (error) {
    //   toast.error("Problème de connexion");
    //   actions.setSubmitting(false);
    // }
  };

  register = ({ actions, values, ...rest }, AuthContextClass) => {
    // try {
    //   firebase
    //     .auth()
    //     .createUserWithEmailAndPassword(values.email, values.password)
    //     .then(auth => {
    //       actions.setSubmitting(false);
    //       AuthContextClass.setAuth(true);
    //       localStorage.setItem("authUser", JSON.stringify(auth));
    //     });
    // } catch (error) {
    //   console.log(error);
    // }
  };
}
