import axios from 'axios'
// import { toast } from 'react-toastify';
import LocalStorageInstance from '../helpers/LocalStorage';

const apiService = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL
})


// Provide the token for all axios request
apiService.interceptors.request.use( 
    config => {
        const token = JSON.parse(LocalStorageInstance.get('authUser'));
        // console.log(token)
        if ( token != null ) {
            config.headers.Authorization = token;
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

apiService.interceptors.response.use( 
    (response, props) => {
        return response;
    }, 
    error  => {
        // On traite ici les différent type d'erreur reçu via l'api
        switch (error.status) {
            // TODO: Possibilité de mettre en place un refresh token
            case 401:
                // Aucun droit pour exécuter la requête ( On deconnecte et redirige vers le login )
                // toast.info("Votre session semble expirée", {
                //     position: toast.POSITION.BOTTOM_CENTER
                // });
                break;
            case 404:
                // Information Request not found
                // toast.error("ERROR 404", {
                //     position: toast.POSITION.BOTTOM_CENTER
                // });
                break;
            default:
                break;
        }
    return Promise.reject(error.response);
});

export default apiService