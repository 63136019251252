import React, { useContext, useState } from 'react'
import { AuthContext } from '../../context/AuthContext'
import canaryLogo from '../../assets/images/canary-logo.svg'
// import userSvg from '../../assets/images/user.svg';
import { Link } from 'react-router-dom'
import ArrowBack from '../../assets/images/arrow-back-history.svg'
import { withRouter } from 'react-router-dom'
import logo from '../../assets/images/logo-black.svg'
import searchSVG from '../../assets/images/search.svg'
import { AppTextContext } from '../../context/AppTextProvider'
import InputBoxDoneTyping from 'react-input-box-done-typing'
import { StickyHeaderContext } from '../../context/StickyHeaderContext'
import clear from '../../assets/images/clear.svg'
import canary from '../../assets/images/canary.svg'
function usePrevious (value) {
  const ref = React.useRef()

  React.useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

const Header = props => {
  const context = useContext(AuthContext)
  const [inputValue, setInputValue] = useState('')
  const [displayBack, setdisplayBack] = useState(false)
  const [previousView, setPreviousView] = useState([])
  const [dontStoreView, setDontStoreView] = useState(false)
  const prevView = usePrevious(props.location.pathname)
  const appText = useContext(AppTextContext)
  const [dataStickHeader, setDataStickHeader] = useContext(StickyHeaderContext)
  const inputParent = React.useRef()

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {}
  }, [])

  function handleScroll () {
    if (window.innerHeight + window.scrollY > 50) {
      document.querySelector('.header').classList.add('sticky')
    } else {
      document.querySelector('.header').classList.remove('sticky')
    }
  }

  React.useEffect(() => {
    if (!dontStoreView && props.location.pathname !== prevView) {
      setPreviousView([...previousView, prevView])
    } else {
      setDontStoreView(false)
    }

    if (
      props.location.pathname === '/' ||
      props.location.pathname.includes('funds')
    ) {
      setdisplayBack(false)
      // alert()
      inputParent.current.children[0].value = ''
    } else {
      setdisplayBack(true)
    }

    return () => {}
  }, [props.location])

  React.useEffect(() => {
    // inputSearch.current.children[0].value = dataStickHeader.inputValue
    if (dataStickHeader.inputValue !== '') {
      setInputValue(dataStickHeader.inputValue)
      props.history.push(`/search/${dataStickHeader.inputValue}`)
      inputParent.current.children[0].value = dataStickHeader.inputValue
      document.querySelector('.input.search').focus()
    }

    return () => {}
  }, [dataStickHeader])

  const navigate = () => {
    setDontStoreView(true)
    let back = previousView[previousView.length - 1]

    if (back === undefined) {
      props.history.push('/')
      return
    }

    let prevViewsRef = [...previousView]
    prevViewsRef.splice(-1, 1)
    setPreviousView(prevViewsRef)

    props.history.push(back)
  }

  return (
    <div className='header'>
      {displayBack && (
        <span
          style={{
            position: 'absolute',
            left: 0
          }}
          className='back'
          onClick={() => navigate()}
        >
          <img style={{ width: 35 }} src={ArrowBack} alt='back' />
          <img
            style={{ maxWidth: 15, position: 'relative', left: -11 }}
            src={logo}
            alt='logo'
          />
        </span>
      )}
      <Link to='/' className='logo' style={{ textDecoration: 'none' }}>
        <img src={canaryLogo} alt='Canary Logo' />
        <h1>
          <img src={canary} alt='Canary' style={{ width: '100%' }} />
        </h1>
      </Link>
      <h2>{appText.home.baseline_logo}</h2>
      <div className='input_row' style={{ width: '100%' }}>
        <img src={searchSVG} alt='search' />
        <div ref={inputParent}>
          <InputBoxDoneTyping
            className='input search'
            doneTyping={value => {
              if (value.length >= 3) {
                setDataStickHeader({ ...dataStickHeader, inputValue: value })
              } else {
                setDataStickHeader({ ...dataStickHeader, inputValue: '' })
              }
            }}
            onChange={value => setInputValue(value)}
            doneTypingInterval={600}
            defaultValue={dataStickHeader.inputValue}
            value={dataStickHeader.inputValue}
            placeholder='Ecrire le nom du fonds ou code ISIN'
          />
          {inputValue !== '' && props.location.pathname.includes('search') && (
            <span
              className='clear'
              onClick={() => {
                setDataStickHeader({ ...dataStickHeader, inputValue: '' })
                setInputValue('')
                inputParent.current.children[0].value = ''
                inputParent.current.children[0].focus()
              }}
            >
              <img src={clear} alt='clear' />
            </span>
          )}
        </div>
      </div>
      <hr />
    </div>
  )
}

export default withRouter(Header)
