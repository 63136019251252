import React, { useContext, useState, useEffect } from 'react'
import { PopupManagerContext } from '../../context/popupManager'
import chevronCard from '../../assets/images/chevron-card.png'
import slugify from 'slugify'

function CardItem (props) {
  const datas = [
    { palier: [0, 19], primaryColor: '#E63E11', text: 'Très mauvais' },
    { palier: [20, 39], primaryColor: '#EE8100', text: 'Mauvais' },
    { palier: [40, 59], primaryColor: '#d4dd5c', text: 'Moyen' },
    { palier: [60, 79], primaryColor: '#85BB2F', text: 'Bon' },
    { palier: [80, 100], primaryColor: '#049F50', text: 'Très bon' }
  ];

  const [displayPopup, setDisplayPopup] = useContext(PopupManagerContext);

  const [index, setIndex] = useState(null);

  const range = (start, end) => {
    return Array(end - start + 1)
      .fill()
      .map((_, idx) => start + idx)
  }

  const checvron = () => {
      return(
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.66667 12L10.6667 8.00001" stroke="#000" stroke-width="3" stroke-linecap="round"/>
              <path d="M6.66666 4L10.6667 8" stroke="#000" stroke-width="3" stroke-linecap="round"/>
          </svg>
      )
  }

  const foundIndexResultData = value => {
    datas.map((elmt, index) => {
      const interval = range(elmt.palier[0], elmt.palier[1])
      if (interval.includes(value)) {
        setIndex(index)
      }
    })
  }

  useEffect(() => {
    foundIndexResultData(props.value)

    return () => {}
  }, [props.value])

  return (
    index !== null && (
      <div
        className='canary_card'
        onClick={() =>
          setDisplayPopup({
            display: true,
            data: {
              background: datas[index].primaryColor,
              note: props.value,
              text: datas[index].text,
              isin: props.isin ? props.isin : 'AT0000686084',
              title: props.title
                ? slugify(props.title, {
                    replacement: '_', // replace spaces with replacement
                    remove: null, // regex to remove characters
                    lower: true // result in lower case
                  })
                : ''
            }
          })
        }
      >
        <h2>{props.title ? props.title : 'Fake name'}</h2>
        {/* <div className="point_card" style={{ color: datas[index].primaryColor }}>
                <span style={{
                backgroundColor: datas[index].primaryColor
            }}/>
                {  datas[index].text }
            </div> */}
        <span
          className='bande'
          style={{
            backgroundColor: datas[index].primaryColor
          }}
        >
          <em>{props.value}</em>
        </span>
          <div className="chevron-card">{checvron()}</div>

      </div>
    )
  )
}

export default CardItem
