import React , { useRef, useState, useEffect } from 'react'
import logoYellow from '../../assets/images/logo-yellow.svg'
import searchSVG from '../../assets/images/search.svg'
import { Link } from 'react-router-dom'

function AccountSearchBar(props) {

    const [searchValue, setSearchValue] = useState( '' )

    const inputSearch = useRef(null);

    useEffect(() => {

        props.onFilter( searchValue )
        
        return () => {
            
        };
        
    }, [searchValue])

    return (
        <div className="search_bar account">
            <Link to='/'><img src={logoYellow} alt='logo' /></Link>
            <div className="input_row" style={{ margin: 0 }}>
                <img src={searchSVG} alt='search' />
                <input 
                    style={{ paddingLeft: 50 }}
                    ref={inputSearch} 
                    value={ searchValue } 
                    onChange={ e => setSearchValue(e.target.value) }
                />      
            </div>
        </div>
    )
}

export default AccountSearchBar
