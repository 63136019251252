//import * as firebase from "firebase";

export default class base {
  logout = (args, context) => {
    // firebase
    //   .auth()
    //   .signOut()
    //   .then(
    //     function() {
    //       context.setState({ isAuth: false });
    //       context.props.history.push("/");
    //       localStorage.removeItem("authUser");
    //     },
    //     function(error) {
    //       // An error happened.
    //     }
    //   );
  };
}

export const AUTH_METHOD = {
  LOGIN: "login",
  REGISTER: "register",
  FORGOT_PASSWORD: "forgotPassword",
  LOGOUT: "logout"
};
