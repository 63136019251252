import React from 'react'
import AccountTemplate from './AccountTemplate';
import AccountLine from './AccountLine'
import cx from 'classnames/bind'
import close from '../../assets/images/close_item_account.png'
import { AppUserSelector } from '../../stores/selectors';
import { connect } from 'react-redux';
import { tryEditUser } from '../../stores/app/app.actions';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

const ComposantInput = ({
    field, 
    form: { touched, errors },
    ...props
  }) => {
    //   console.log(props)
    return(
        <div className={cx(
            'form-group',
            { invalid : errors[field.name] }
        )}>
            <input 
                placeholder={ props.label } 
                type="text" 
                autoComplete='off'
                className="form-control" 
                // value={ props.value }
                {...props} 
                {...field} 
                onChange={ e => props.passwordchange( e.target.value , field.name) }
            />
        </div>
    )
  };

function MyAccount(props) {

    const [edit, setEdit] = React.useState(null)
    const [canSubmitPwd, setCanSubmitPwd] = React.useState(false)

    
    const [validPassword, setValidPassword] = React.useState({
        password: '',
        password2: ''
    })

    const [userFormData, setUserFormData] = React.useState({
        email:"",
        password: "",
        phone: "",
        name :""
    })

    React.useEffect(() => {

        setEdit(null)
        setUserFormData({
            email:"",
            password: "",
            phone: "",
            name :""
        })

        return () => {
            
        };

    }, [props.user])

    React.useEffect(() => {

        if( validPassword.password !== '' && validPassword.password2 !== '' ) {
            if( validPassword.password === validPassword.password2 ) {
                setCanSubmitPwd(true)
            } else {
                setCanSubmitPwd(false)
            }
           
        }

        return () => {
            
        };

    }, [validPassword])

    const EditUser = (index) => {
        switch (index) {
            case 'name':
                props.tryEditUser({
                    name: userFormData.name
                })
                
                break;
            case 'email':
                props.tryEditUser({
                    email: userFormData.email
                })
                break;
            case 'password':
                props.tryEditUser({
                    password: validPassword.password
                })
                break;
            default:
                break;
        }
    }

    

    const onChangePassword = (value, name) => {
        console.log(value, name)
        setValidPassword({
             ...validPassword,
             [name]:value
        })
    }

    const userSchema = Yup.object().shape({
        password: Yup.string().required('Champs obligatoire').min(8),
        password2: Yup.string().required('Champs obligatoire').oneOf([Yup.ref('password'), null], 'Mot de passe non identique'),
    });

    return (
        props.user &&
        <AccountTemplate title="Mon Compte" search={false}>
            <AccountLine editing={ edit === "name" }>
                <div>
                    <h4>
                        <span>
                            { edit === 'name' && <img src={ close } alt="close" onClick={ () => setEdit(null) } /> }
                            Votre nom
                        </span>
                        <button 
                            disabled={ userFormData.name === '' && edit === 'name' }
                            className={cx({ 'editing' : edit === "name" })} 
                            type="button" 
                            onClick={ () => { edit === 'name' ? EditUser('name') : setEdit('name') } }
                        >{ edit === 'name' ? 'Ok' : 'Modifier' }</button>
                    </h4>
                    { edit !== 'name' &&  props.user.id.name && <h5> { props.user.id.name }</h5> }
                    { edit === 'name' &&
                        <div className="edit_content">
                            <input 
                                type="text" 
                                value={ props.user.id.name && userFormData.name === '' ? props.user.id.name : userFormData.name }
                                onChange={ e => setUserFormData({ ...userFormData , name: e.target.value }) }
                            />
                        </div>
                    }
                </div>
            </AccountLine>

            <AccountLine editing={ edit === "email" }>
                <div>
                    <h4>
                        <span>
                            { edit === 'email' && <img src={ close } alt="close" onClick={ () => setEdit(null) } />  }
                            Email
                        </span>
                       <button 
                            disabled={ userFormData.email === '' && edit === 'email' }
                            className={cx({ 'editing' : edit === "email" })}  
                            type="button" 
                            onClick={ () => { edit === 'email' ? EditUser('email') : setEdit('email') } }
                        >{ edit === 'email' ? 'Ok' : 'Modifier' }</button>
                    </h4>
                    { edit !== 'email' && <h5>{ props.user.id.email }</h5> } 
                    { edit === 'email' &&
                        <div className="edit_content">
                            <input 
                                type="text" 
                                value={ props.user.id.email && userFormData.email === '' ? props.user.id.email : userFormData.email }
                                onChange={ e => setUserFormData({ ...userFormData , email: e.target.value }) }
                            />
                        </div>
                    }
                </div>
            </AccountLine>

            <AccountLine editing={ edit === "password" } >
                <div >
                    <h4>
                       
                        <span>
                            { edit === 'password' && <img src={ close } alt="close" onClick={ () => setEdit(null) } /> }
                             Mot de passe
                        </span>
                        <button 
                            disabled={ !canSubmitPwd && edit === 'password' }
                            className={cx({ 'editing' : edit === "password" })}  
                            type="button" 
                            onClick={ () => { canSubmitPwd ? EditUser('password') : setEdit('password') } }
                        >{ edit === 'password' ? 'Ok' : 'Modifier' }</button>
                    </h4>
                    { edit === 'password' &&
                        <div className="edit_content">
                            <Formik
                                onSubmit=''
                                // initialValues={{ password: '', password2: ''}}
                                validationSchema={userSchema}
                                validateOnChange={true}
                            >
                            {() => (
                                <form className="w-100 d-flex flex-column">
                                    { validPassword.password !== validPassword.password2 &&
                                    <div className="error-message">
                                        <span>
                                            Les 2 mot de passes ne sont pas identiques.
                                        </span>
                                    </div>
                                    }
                                    <Field 
                                        name="password" 
                                        type="password" 
                                        label="Mot de passe" 
                                        component={ComposantInput} 
                                        value={ validPassword.password }
                                        passwordchange = { (value, name) => onChangePassword(value, name) }
                                    />
                                    <Field 
                                        name="password2" 
                                        type="password" 
                                        label="Confirmation mot de passe" 
                                        component={ComposantInput} 
                                        value={ validPassword.password2 }
                                        passwordchange = { (value, name) => onChangePassword(value, name) }
                                    />
                                </form>
                            )}
                            </Formik>
                        </div>
                        }
                </div>
            </AccountLine>


        </AccountTemplate>
                    
    )
}

export default connect(
    state => ({
        user: AppUserSelector(state),
    }),{
        tryEditUser
    }
)(MyAccount)

