import React from 'react';
import axios from 'axios';
// import triangleImg from '../../assets/images/triangle.svg'
import { AppTextContext } from '../../context/AppTextProvider';

function Newsletter(props) {

    const [emailError, setEmailError] = React.useState(null)
    const [email, setEmail] = React.useState('')
    const appText = React.useContext(AppTextContext)

    const subscribeUser = () => {

        axios({
          method: 'post',
          url: 'https://api.sendinblue.com/v3/contacts',
          headers: {'api-key': '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'},
          data: {
            email: email,
            listIds : [4]
          }
        })
        .then( () => setEmailError({message:'Merci pour votre inscription.',color : "#004D33"}))
        .catch( error => {
          switch (error.response.data.code) {
            case 'invalid_parameter':
              setEmailError({message:'Email invalide.',color : "#F44336"})
              break;
            case 'duplicate_parameter':
              setEmailError({message:'Merci pour votre inscription.',color : "#004D33"})
              break;
            default:
                setEmailError({message:'Une erreur s\'est produite.',color : "#F44336"})
              break;
          }
        })
  
      }

      function validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const renderTriangle = () => {
      return(
        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.956248 1.53C0.539968 0.863949 1.01881 0 1.80425 0H18.1958C18.9812 0 19.46 0.863951 19.0438 1.53L10.848 14.6432C10.4563 15.2699 9.54367 15.2699 9.152 14.6432L0.956248 1.53Z" fill={props.svgColor ? props.svgColor : "#181830"}/>
        </svg>        
      )
    }
      
    return (
        <div className="box_green" {...props} >
            <h4 style={{
                margin: 0,
                textAlign: 'left',
                fontSize: 20,
                fontWeight: 'bold'
            }}>
                Newsletter Canary
                <div>{ renderTriangle() }</div>
            </h4>
            <p style={{
                    margin: '5px 0',
                    fontWeight: 'normal',
                    color: '#000',
                    fontSize: 15,
                    lineHeight:'24px'
            }}>
              {appText.home.nl_1_content}
            </p>
            <div style={{ position: 'relative' }}>
                <input 
                  onChange={ (e) => { 
                    setEmail(e.target.value); 
                  }
                  } 
                  value={email} 
                  type="email" 
                  placeholder={ appText.home.nl2_placeholer } 
                  style={ props.inputStyle }
                />
                { email !== '' && validateEmail( email ) &&
                  <button 
                  type="button" 
                  className=""
                  onClick={ subscribeUser }
                  >{ appText.home.nl2_button }</button>
                }
            </div>
            { emailError && <span className='mt-2' style={{ color: emailError.color, fontWeight: 'bold', display: 'block' }}>{emailError.message}</span> }
        </div>
    )
}

export default Newsletter
