import React from 'react';
import triangleImg from '../../assets/images/triangle.svg'

const Content = (props) => {
    return(
        <section 
            className="content"
            style={{ ...props.style, backgroundColor: props.sectionBackground }}
            id={ props.id }
        >
            { props.title && <h2 dangerouslySetInnerHTML={ {__html:props.title + ` <img src=${triangleImg} alt='triangle' /> `} } /> }
            <div className="wrapper">
                { props.content && <p dangerouslySetInnerHTML={ {__html:props.content} } /> } 
                {props.children}
            </div>
        </section>
        
    )
}

export default Content;