import React from 'react'
import { AppTextContext } from '../../context/AppTextProvider';

function FondationCanary() {

    const apptext = React.useContext(AppTextContext)

    return (
        <>
            <h2>{ apptext.footer.FondationCanaryTitre }</h2>
            <span dangerouslySetInnerHTML={ {__html: apptext.footer.FondationCanary  } } />
        </>
    )
}

export default FondationCanary
