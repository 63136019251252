import initialState from "./initialState";
import * as actions from './app.actions';

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.REQUEST_USER: {
            return {
                ...state,
                isLoading: true
            }
        }
        case actions.FETCH_USER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: null,
                user: action.user
            }
        }
        case actions.FETCH_TEXT_SUCCESS: {
            return {
                ...state,
                appText: action.data
            }
        }
        case actions.FETCH_CATEGORIES_SUCCESS: {
            return { ...state, categories: action.data }
        }
        case actions.FETCH_USER_FUNDS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: null,
                myFunds: action.funds
            }
        }
        case actions.FETCH_USER_HISTORY_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: null,
                history: action.history
            }
        }
        case actions.FETCH_USER_ERROR: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            }
        }
        case actions.FETCH_USER_FUNDS_ERROR: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            }
        }
        default: {
            return state
        }
    }
}