import React from "react";
// import  triangle  from '../../assets/images/triangle.svg'
// import goBack from '../../assets/images/arrow-back-history.svg'
import { withRouter } from "react-router-dom";
// import { Link } from 'react-router-dom'
import triangleImg from "../../assets/images/triangle.svg";
// import FondationCanary from '../modal/FondationCanary'
// import { ModalInfoManagerContext } from '../../context/ModalInfoManager'
import { AppTextContext } from "../../context/AppTextProvider";
import * as scroll from "react-scroll";
import { Helmet } from "react-helmet";

function QuiSommeNous(props) {
  const appText = React.useContext(AppTextContext);
  // const [dataModal, setDataModal]  = React.useContext( ModalInfoManagerContext )

  React.useEffect(() => {
    scroll.animateScroll.scrollToTop();

    return () => {};
  }, []);

  return (
    <>
      <Helmet>
        <title>L'équipe Canary</title>
        <meta
          name="description"
          content="Découvrez l'équipe derrière le Canary"
        />
      </Helmet>
      <div className="account_template">
        <div className="page">
          <h1>
            {appText.footer.QuisommesnousTitre}
            <img src={triangleImg} alt="triangle" />
          </h1>
          <div className="wrapper">
            <span
              dangerouslySetInnerHTML={{ __html: appText.footer.Quisommesnous }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(QuiSommeNous);
