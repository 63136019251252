import React from 'react'
import cx from 'classnames/bind'

const SliderNote = (props) => {

    const lines = [
        {
            palier: [0,19],
            color: '#E63E11',
            leftNum: 0
        },
        {
            palier: [20, 39],
            color: '#EE8100',
            leftNum: 20
        },
        {
            palier: [40,59],
            color: '#d4dd5c',
            leftNum: 40
        },
        {
            palier: [60,79],
            color: '#85BB2F',
            leftNum: 60
        },
        {
            palier: [80,100],
            color: '#049F50',
            leftNum: 80,
            rightNum: 100,
        }
    ]


    const inInterval = ( palier, sliderValue ) => {
        const interval = range(palier[0], palier[1]); 
        return interval.includes( sliderValue )
    }

    const range = (start, end) => {
        return Array(end - start + 1).fill().map((_, idx) => start + idx)
    }

    return (
        <div className="slider-container">
        { 
            lines.map( (pal, index) =>  ( 
                <span 
                        className={cx({
                        "palier": true,
                        active : inInterval( pal.palier, props.value )
                    })}  
                    style={{ backgroundColor: pal.color }}
                    key={index}
                >
                    <em className="left" style={{ color: pal.color }}>{ pal.leftNum }</em>
                    { pal.rightNum && <em className="left" style={{ color: pal.color }}>{ pal.rightNum }</em>}
                </span>)
                ) 
        }
        </div>
    )
}

export default SliderNote
 