import React, { Component } from 'react'
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import cx from 'classnames/bind';
import { Link, withRouter } from 'react-router-dom'
import { AUTH_METHOD } from '../../../auth/base';

const ComposantErreur = props => {
    return <div className="error-message"><span>{props.children}</span></div>
};
  
const ComposantInput = ({
    field, 
    form: { touched, errors },
    ...props
  }) => {
    return(
        <div className={ cx(
            'form-group',
            { invalid : errors[field.name] }
        )}>
            <label> { props.label } </label>
            <input type="text" {...props} className="form-control" {...field} />
        </div>
    )
  };

class Login extends Component {

    constructor(props) {
      super(props);
      this.state = {
        isSubmitting: false
      }
    }

    submit = (values,actions) => {
      this.props.authContext.call( 
        AUTH_METHOD.LOGIN ,
        {values, actions}
      );
    }
    
    userSchema = Yup.object().shape({
        email: Yup.string().required('Champs obligatoire').email("L'email doit être valide"),
        password: Yup.string().required('Champs obligatoire')
    });

    render() {
        return (
          <div className="container-fluid mt-4 mb-4 d-flex flex-column justify-content-center align-items-center">
            <em className="info_form">Connectez-vous à votre compte.</em>
            <Formik
              onSubmit={this.submit}
              initialValues={{ email: '', password: ''}}
              validationSchema={this.userSchema}
              validateOnChange={true}
            >
              {({
              handleSubmit,
              isSubmitting,
              actions
              }) => (
                <form onSubmit={ handleSubmit } className="w-100 d-flex flex-column">
                  <Field name="email" type="email" label="Email" component={ComposantInput} />
                  <ErrorMessage name="email" component={ComposantErreur} />
                  <Field name="password" type="password"  label="Mot de passe" component={ComposantInput} />
                  <ErrorMessage name="password" component={ComposantErreur} />
                  <button type="submit" className="button white w-100" disabled={ isSubmitting  || this.state.isSubmitting }>
                    Se connecter
                  </button>
                  <span className="form_link text-center">
                    <Link to='/auth/register'>Inscription</Link>
                    <span className="sep">|</span>
                    <Link to='/auth/forgot-password'>Mot de passe oublié?</Link>
                  </span>
                </form>
              )}
            </Formik>
          </div>
    
        )
    }
}

export default withRouter(Login)