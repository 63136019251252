import { createSelector } from 'reselect';

export const searchFundsSelector = state => state.search;

export const searchIsLoadingSelector = createSelector(
    [ searchFundsSelector ],
    funds => funds.isLoading
)

export const searchDataSelector = createSelector(
    [searchFundsSelector],
    funds => funds.data
)

export const searchFundsNumberSelector = createSelector(
    [searchFundsSelector],
    funds => funds.number
)
