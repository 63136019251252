import React, { Component } from 'react'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import cx from 'classnames/bind'
import { Link } from 'react-router-dom'
import { AUTH_METHOD } from '../../../auth/base'

const ComposantInput = ({ field, form: { touched, errors }, ...props }) => {
  return (
    <div className={cx('form-group', { invalid: errors[field.name] })}>
      <label> {props.label} </label>
      <input
        type='text'
        {...props}
        autoComplete='off'
        className='form-control'
        {...field}
      />
      {touched[field.name] && errors[field.name] && (
        <div className='error-message'>
          <span>{errors[field.name]}</span>
        </div>
      )}
    </div>
  )
}

class Register extends Component {
  submit = (values, actions) => {
    this.props.authContext.call(AUTH_METHOD.REGISTER, { values, actions })
  }

  userSchema = Yup.object().shape({
    email: Yup.string()
      .required('Champs obligatoire')
      .email("L'email doit être valide"),
    password: Yup.string().required('Champs obligatoire'),
    password2: Yup.string()
      .required('Champs obligatoire')
      .oneOf([Yup.ref('password'), null], 'Mot de passe non identique')
  })

  render () {
    return (
      <div className='container-fluid mt-5 mb-5 d-flex flex-column justify-content-center align-items-center'>
        <em className='info_form'>Créer un compte.</em>
        <Formik
          onSubmit={this.submit}
          initialValues={{ email: '', password: '' }}
          validationSchema={this.userSchema}
          validateOnChange={true}
        >
          {({ handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit} className='w-100 d-flex flex-column'>
              <Field
                name='email'
                type='email'
                label='Email'
                component={ComposantInput}
              />
              {/* <ErrorMessage name="email" component={ComposantErreur} /> */}
              <Field
                name='password'
                type='password'
                label='Mot de passe'
                component={ComposantInput}
              />
              {/* <ErrorMessage name="password" component={ComposantErreur} /> */}
              <Field
                name='password2'
                type='password'
                label='Confirmation mot de passe'
                component={ComposantInput}
              />
              {/* <ErrorMessage name="password2" component={ComposantErreur} /> */}
              <button
                type='submit'
                className='button white w-100'
                disabled={isSubmitting}
              >
                Créer un compte
              </button>
              <span className='form_link text-center'>
                <Link to='/auth/login'>Connexion</Link>
              </span>
            </form>
          )}
        </Formik>
      </div>
    )
  }
}

export default Register
