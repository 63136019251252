import { Home } from "../components";
import Auth from "../features/auth";
import LazySearch from "../features/search/Lazy";
import LazyFund from "../features/funds/Lazy";
import History from "../components/account/History";
import Funds from "../components/account/Funds";
import MyAccount from "../components/account/MyAccount";
import MentionsLegale from "../components/shared/MentionsLegale";
import QuiSommeNous from "../components/shared/QuiSommeNous";
import Faq from "../components/shared/Faq";
import Cgu from "../components/shared/Cgu";
import Cookies from "../components/shared/Cookies";

export const routes = [
  {
    path: "/",
    private: false,
    component: Home,
    exact: true
  },
  {
    path: "/search/:term",
    private: false,
    component: LazySearch,
    exact: true
  },
  {
    path: "/search/category/:category",
    private: false,
    component: LazySearch,
    exact: true
  },
  {
    path: "/search/",
    private: false,
    component: LazySearch,
    exact: true
  },
  {
    path: "/my-account",
    private: true,
    component: MyAccount,
    exact: true
  },
  {
    path: "/account/history/",
    private: true,
    component: History,
    exact: true
  },
  {
    path: "/account/funds/",
    private: true,
    component: Funds,
    exact: true
  },
  {
    path: "/funds/:slug-:isin",
    private: false,
    component: LazyFund,
    exact: true
  },
  {
    path: "/mentions-legales",
    private: false,
    component: MentionsLegale,
    exact: true
  },
  {
    path: "/qui-sommes-nous",
    private: false,
    component: QuiSommeNous,
    exact: true
  },
  {
    path: "/cookies",
    private: false,
    component: Cookies,
    exact: true
  },
  {
    path: "/cgu",
    private: false,
    component: Cgu,
    exact: true
  },
  {
    path: "/faq",
    private: false,
    component: Faq,
    exact: true
  },
  // {
  //     path: '/todos/:state',
  //     private: true,
  //     component: LazyTodo,
  //     exact: true,
  //     routeProps : {
  //         main_nav: true,
  //         menu_label: 'Todos',
  //         menu_link_to: '/todos/all',
  //         auth_redirect_path : '/auth/login'
  //     }
  // },
  {
    path: "/auth/:action",
    private: false,
    component: Auth,
    exact: true
  }
];
