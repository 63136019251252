import React from "react";
// import  triangle  from '../../assets/images/triangle.svg'
// import goBack from '../../assets/images/arrow-back-history.svg'
import { withRouter } from "react-router-dom";
// import Header from '../header/Header'
import triangleImg from "../../assets/images/triangle.svg";
import * as scroll from "react-scroll";
import { AppTextContext } from "../../context/AppTextProvider";

function MentionsLegale(props) {
  const appText = React.useContext(AppTextContext);

  React.useEffect(() => {
    scroll.animateScroll.scrollToTop();
    return () => {};
  }, []);

  return (
    <div className="account_template">
      <div className="page">
        <h1>
          {appText.footer.MentionslegalesTitre}
          <img src={triangleImg} alt="triangle" />
        </h1>
        <div className="wrapper">
          <span
            dangerouslySetInnerHTML={{ __html: appText.footer.Mentionslegales }}
          />
        </div>
      </div>
    </div>
  );
}

export default withRouter(MentionsLegale);
