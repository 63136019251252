import React, { createContext } from 'react';
import { connect } from 'react-redux';

export const AppTextContext = createContext();

function AppTextProvider(props) {

  return (
    <AppTextContext.Provider value={ props.appText[props.lang] }>
      { props.children }
    </AppTextContext.Provider>
  );

}

export default connect(
    state => ({
        appText: state.app.appText
    })
)(AppTextProvider)
