import React, { createContext, useState } from 'react'

export const AccountTemplateContext = createContext();

function AccountProvider({ children }, props) {

    const [searchFilter, setSearchFilter] = useState('');

    return (
      <AccountTemplateContext.Provider value={[searchFilter, setSearchFilter]}>
        {children}
      </AccountTemplateContext.Provider>
    );
  
  }
  
  export default AccountProvider
  