export default {
    user : null,
    history: [],
    myFunds:[],
    categories: [],
    appText: {
        fr: {
            home: {
                search_form_title: "J'inscris le fonds à évaluer",
                search_form_placeholder: 'Nom du fonds ou code ISIN',
                search_form_footer: 'Code ISIN ?',
                palmares_title: 'Le palmarès',
                palmares_button_text: 'Et les autres fonds ?',
                button_text_nl_1: "Je m'inscris",
                nl_1_content: "pour suivre l'évolution de ma sélection de fonds",
                canary_what_title: "A quoi sert Canary ?",
                canary_what_content: 'Canary est un système de notation pour savoir si un fonds est bon ou pas.',
                why_canary_title: 'Pourquoi <br/> Canary ?',
                why_canary_content: '<strong>Parce que transformer le jargon financier en choix éclairé, c’est un lourd travail ... à temps plein.</strong> Pour vous faciliter la tâche, Canary vous donne :',
                why_canary_repeater_text : [
                    'Un score unique avec un barème simple de 0 à 100',
                    'Des informations sur les performances, les frais prélevés et le niveau de risque',
                    'Suivez les fonds que vous avez sélectionnés',
                    'Suivez les fonds que vous avez sélectionnés',
                ],
                how_work_canary_title: 'Comment <br/> ça marche ?',
                how_work_canary_content: '<strong>Le score Canary</strong> condense une série de données en une seule note.',
                how_work_test_text: 'Exemple',
                text_very_bad_example : 'De 0 à 19',
                text_bad_example : 'De 20 à 39',
                text_medium_example : 'De 40 à 59',
                text_good_example : 'De 60-79',
                text_very_good_example : 'De 80-100',
                footer_text_1 : 'Chercher un fonds',
                footer_text_2 : 'Créer un compte',
                footer_text_3 : 'Se connecter',
                nl_2_content:  "Recevez 2 fois par mois notre sélection des meilleurs fonds du moment.",
                nl2_placeholer: "Entrez votre adresse email",
                nl2_button: "Je m'inscris",
            },
            fundDetails: {
                explain_note_text: "Expliquez-moi cette note",
                explain_note_content : "La note Canary évalue un fonds relativement à ses comparables. Elle prend en compte les performances, les frais prélevés et les critères d'investissement durable (ESG, ISR). Elle est mise à jour mensuellement.",
                follow_button_text: "Suivre ce fond",
                unfollow_button_text: "Ne plus suivre ce fond",
                perf_title: "Performances",
                isin_text: "Le code ISIN d'un fonds est son numéro d'identification avec 2 lettres suivies de 10 chiffres. Il figure généralement sur votre relevé de compte et dans la presse spécialisée à côté du nom du fonds.",
                div_flexible_text: "Fonds dans lequel le gérant modifie sans contrainte la répartition entre actions, obligations et cash en fonction de ses anticipations",
                frais_gestion_title: "Frais de gestion",
                frais_gestion_detail_text: "Détaillez moi cette note",
                frais_gestion_popup_text: "<p>Les frais de gestion servent essentiellement à rémunérer la société de gestion. Ils sont en général compris entre 0,5 % et 3 % par an pour les fonds les plus chers.</p>",
                risk_indice_title: 'Echelle de Risque',
                risk_indice_subtitle: 'Norme officielle européenne',
                risk_indice_low_text: 'À Risque plus faible, <br/> Rendement potentiel plus faible.',
                risk_indice_high_text: 'À risque plus élevé,  <br/> rendement potentiel plus élevé.',
                risk_indice_detail_text: "D’où vient cette note",
                risk_indice_detail_popup_text: "<p>L'échelle de risque SRRI s'applique identiquement à tous les fonds. Elle figure dans les documents obligatoires du fonds visés par les autorités réglementaires de chaque pays en Europe.</p>",
                label_isr_text: ' <strong>Ce fonds a obtenu le label</strong> <br /> Investissement <br /> Socialement Responsable',
                label_isr_detail_title: "Le label ISR en quelques mots",
                label_isr_popup_text: "<p>Le label ISR est un outil pour choisir des placements responsables et durables. Sous l'autorité du ministère des Finances, il vise à rendre plus visibles les produits d’investissement socialement responsables (ISR) </p>",
                category_title: 'Dans la même <br /> catégorie',
                category_button_text: " Et les autres"
            }
        },
        en : {
            home: {
                search_form_title: "J'inscris le fonds à évaluer",
                search_form_placeholder: 'Nom du fonds ou code ISIN',
                search_form_footer: 'Code ISIN ?',
                palmares_title: 'Le palmarès',
                palmares_button_text: 'Et les autres fonds ?',
                button_text_nl_1: "Je m'inscris",
                nl_1_content: "pour suivre l'évolution de ma sélection de fonds",
                canary_what_title: "A quoi sert Canary ?",
                canary_what_content: 'Canary est un système de notation pour savoir si un fonds est bon ou pas.',
                why_canary_title: 'Pourquoi <br/> Canary ?',
                why_canary_content: '<strong>Parce que transformer le jargon financier en choix éclairé, c’est un lourd travail ... à temps plein.</strong> Pour vous faciliter la tâche, Canary vous donne :',
                why_canary_repeater_text : [
                    'Un score unique avec un barème simple de 0 à 100',
                    'Des informations sur les performances, les frais prélevés et le niveau de risque',
                    'Suivez les fonds que vous avez sélectionnés',
                    'Suivez les fonds que vous avez sélectionnés',
                ],
                how_work_canary_title: 'Comment <br/> ça marche ?',
                how_work_canary_content: '<strong>Le score Canary</strong> condense une série de données en une seule note.',
                how_work_test_text: 'Exemple',
                text_very_bad_example : 'De 0 à 19',
                text_bad_example : 'De 20 à 39',
                text_medium_example : 'De 40 à 59',
                text_good_example : 'De 60-79',
                text_very_good_example : 'De 80-100',
                footer_text_1 : 'Chercher un fonds',
                footer_text_2 : 'Créer un compte',
                footer_text_3 : 'Se connecter',
                nl_2_content:  "Recevez 2 fois par mois notre sélection des meilleurs fonds du moment.",
                nl2_placeholer: "Entrez votre adresse email",
                nl2_button: "Je m'inscris",
            },
            fundDetails: {
                explain_note_text: "Expliquez-moi cette note",
                explain_note_content : "La note Canary évalue un fonds relativement à ses comparables. Elle prend en compte les performances, les frais prélevés et les critères d'investissement durable (ESG, ISR). Elle est mise à jour mensuellement.",
                follow_button_text: "Suivre ce fond",
                unfollow_button_text: "Ne plus suivre ce fond",
                perf_title: "Performances",
                isin_text: "Le code ISIN d'un fonds est son numéro d'identification avec 2 lettres suivies de 10 chiffres. Il figure généralement sur votre relevé de compte et dans la presse spécialisée à côté du nom du fonds.",
                div_flexible_text: "Fonds dans lequel le gérant modifie sans contrainte la répartition entre actions, obligations et cash en fonction de ses anticipations",
                frais_gestion_title: "Frais de gestion",
                frais_gestion_detail_text: "Détaillez moi cette note",
                frais_gestion_popup_text: "<p>Les frais de gestion servent essentiellement à rémunérer la société de gestion. Ils sont en général compris entre 0,5 % et 3 % par an pour les fonds les plus chers.</p>",
                risk_indice_title: 'Echelle de Risque',
                risk_indice_subtitle: 'Norme officielle européenne',
                risk_indice_low_text: 'À Risque plus faible, <br/> Rendement potentiel plus faible.',
                risk_indice_high_text: 'À risque plus élevé,  <br/> rendement potentiel plus élevé.',
                risk_indice_detail_text: "D’où vient cette note",
                risk_indice_detail_popup_text: "<p>L'échelle de risque SRRI s'applique identiquement à tous les fonds. Elle figure dans les documents obligatoires du fonds visés par les autorités réglementaires de chaque pays en Europe.</p>",
                label_isr_text: ' <strong>Ce fonds a obtenu le label</strong> <br /> Investissement <br /> Socialement Responsable',
                label_isr_detail_title: "Le label ISR en quelques mots",
                label_isr_popup_text: "<p>Le label ISR est un outil pour choisir des placements responsables et durables. Sous l'autorité du ministère des Finances, il vise à rendre plus visibles les produits d’investissement socialement responsables (ISR) </p>",
                category_title: 'Dans la même <br /> catégorie',
                category_button_text: " Et les autres"
            }
        }
        
    },
    isLoading: false,
    error: null
}