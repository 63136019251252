import React from 'react'
import AccountTemplate from './AccountTemplate'
import { connect } from 'react-redux'
import { AppUserHistorySelector } from '../../stores/selectors'
import { fetchUserHisotry } from '../../stores/app/app.actions'
import CardItem from '../shared/CardItem'
import { AccountTemplateContext } from '../../context/AccountContext'

// import CardItem from '../shared/CardItem';

function History (props) {
  const [filter] = React.useContext(AccountTemplateContext)
  const [dataFiltered, setDataFiltered] = React.useState(null)

  React.useEffect(() => {
    props.fetchUserHisotry()

    return () => {}
  }, [])

  React.useEffect(() => {
    let filtered = []

    if (filter !== '') {
      props.userHistory.filter(fund => {
        if (fund.isin) {
          fund.isin.toLowerCase().includes(filter.toLowerCase()) &&
            filtered.push(fund)
        }

        if (fund.name) {
          fund.name.toLowerCase().includes(filter.toLowerCase()) &&
            filtered.push(fund)
        }
      })

      console.log([...new Set(filtered)])

      setDataFiltered([...new Set(filtered)])
    } else {
      setDataFiltered(null)
    }

    return () => {}
  }, [filter])

  return (
    <AccountTemplate title='Historique'>
      <div className='wrap'>
        {dataFiltered === null && props.userHistory
          ? props.userHistory.map((fund, index) => (
              <CardItem
                key={index}
                isin={fund.isin && fund.isin}
                value={fund.best_of ? parseInt(fund.best_of) : parseInt(0)}
                title={fund.name}
              />
            ))
          : dataFiltered.map((fund, index) => (
              <CardItem
                key={index}
                isin={fund.isin && fund.isin}
                value={fund.best_of ? parseInt(fund.best_of) : parseInt(0)}
                title={fund.name}
              />
            ))}
      </div>
    </AccountTemplate>
  )
}

export default connect(
  state => ({
    userHistory: AppUserHistorySelector(state)
  }),
  { fetchUserHisotry }
)(History)
