import React, { createContext, useState } from 'react';

export const StickyHeaderContext = createContext();

export function StickyHeaderProvider({ children }) {

  const [data, setData] = useState({ 
    inputValue: ''
  });

  return (
    <StickyHeaderContext.Provider value={
        [data, setData]
    }>
      {children}
    </StickyHeaderContext.Provider>
  );
}