import React, { Component } from 'react'
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import cx from 'classnames/bind';
import { Link } from 'react-router-dom'
import { AUTH_METHOD } from '../../../auth/base';

const ComposantErreur = props => {
    return <div className="error-message"><span>{props.children}</span></div>
};
  
const ComposantInput = ({
    field, 
    form: { touched, errors },
    ...props
  }) => {
    return(
        <div className={ cx(
            'form-group',
            { invalid : errors[field.name] }
        )}>
            <label> { props.label } </label>
            <input type="text" {...props} className="form-control" {...field} />
        </div>
    )
  };

class ForgotPassword extends Component {

  submit = (values,actions) => {
    this.props.authContext.call( 
      AUTH_METHOD.FORGOT_PASSWORD,
      {values, actions},
    );
  }
    
    userSchema = Yup.object().shape({
        email: Yup.string().required('Champs obligatoire').email("L'email doit être valide"),
    });

    render() {
        return (
          <div className="container-fluid mt-4 mb-4 d-flex flex-column justify-content-center align-items-center">
            <em className="info_form">Entrez votre adresse email pour récupérer votre mot de passe</em>
            <Formik
              onSubmit={this.submit}
              initialValues={{ email: ''}}
              validationSchema={this.userSchema}
              validateOnChange={true}
            >
              {({
              handleSubmit,
              isSubmitting,
              isvalid
              }) => (
                <form onSubmit={ handleSubmit } className="w-100 d-flex flex-column">
                  <Field name="email" type="email"  label="Email" component={ComposantInput} />
                  <ErrorMessage name="email" component={ComposantErreur} />
                  <button type="submit" className="button white w-100" disabled={ isSubmitting }>
                    Envoyer
                  </button>
                  <span className="form_link text-center">
                    <Link to='/auth/login'>Connexion</Link>
                  </span>
                </form>
              )}
            </Formik>
          </div>
        )
    }
}

export default ForgotPassword