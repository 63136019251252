import initialState from "./initialState";
import * as actions from './home.actions';

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.REQUEST_PALMARES: {
            return {
                ...state,
                isLoading: true
            }
        }
        case actions.REQUEST_PALIER: {
            return {
                ...state,
                isLoading: true
            }
        }
        case actions.FETCH_PALMARES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: null,
                palmares: action.data
            }
        }
        case actions.FETCH_PALIER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: null,
                testData: action.data
            }
        }
        case actions.FETCH_PALMARES_ERROR: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            }
        }
        case actions.FETCH_PALIER_ERROR: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            }
        }
        default: {
            return state
        }
    }
}