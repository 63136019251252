import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Auths from '../auth'
import * as _ from 'lodash'
// import LocalStorageInstance from '../helpers/LocalStorage'
// import * as firebase from 'firebase'
// import apiService from '../conf/api.custom'
// import { toast } from 'react-toastify';

export const AuthContext = React.createContext()

class AuthProvider extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isAuth: false,
      authData: null,
      error: null,
      success: null
    }

    let AuthChoice = _.find(Auths, { type: this.props.provider })
    this.auth = _.isUndefined(AuthChoice) ? undefined : new AuthChoice.model()
  }

  call = (method, params = {}) => {
    !_.isUndefined(this.auth) && this.auth[method](params, this)
  }

  setAuth = value => {
    this.setState({ isAuth: value })
  }

  componentDidMount () {
    // this.setAuthObserver()
  }

  // setAuthObserver = () => {
  //   if (LocalStorageInstance.get('authUser')) {
  //     apiService
  //       .post('debug/auth/valid_token', {
  //         headers: {
  //           'Content-Type': 'application/json',
  //           Authorization: JSON.parse(LocalStorageInstance.get('authUser'))
  //         }
  //       })
  //       .then(response => {
  //         if (response.status === 200) {
  //           this.setAuth(true)
  //         }
  //       })
  //       .catch(err => {
  //         this.setAuth(null)
  //         // toast.info('Vous avez été deconnecté.')
  //         LocalStorageInstance.remove('authUser')
  //       })
  //   } else {
  //     this.setAuth(null)
  //   }
  // }

  render () {
    return (
      <AuthContext.Provider
        value={{
          isAuth: this.state.isAuth,
          ...this.state,
          call: this.call,
          setAuth: this.setAuth
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    )
  }
}

export default withRouter(AuthProvider)
