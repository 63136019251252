import base from "./base";
import apiService from "../conf/api.custom";
import { toast } from 'react-toastify';
import LocalStorageInstance from "../helpers/LocalStorage";

export default class CustomAuth extends base {

    login = ( {actions, values, ...rest}, AuthContextClass ) => {
        apiService.post(
            'auth/login',
            {
                "email": values.email,
                "password": values.password,
            }
          )
          .then( response => {
              if( response.status === 200 ) {
                // On peut connecter le USER dans l'APP
                actions.setSubmitting(false);
                AuthContextClass.setAuth(true);
                LocalStorageInstance.store('authUser', JSON.stringify(response.data));
              }
          })
          .catch( err => {
            actions.setSubmitting(false);
            toast.error( 'Login ou mot de passe non valide' , {
                position: toast.POSITION.BOTTOM_CENTER
            });
          });
    } 

    register = ( {actions, values, ...rest}, AuthContextClass ) => {
        apiService.post(
                    'auth/register',
                    {
                        "email": values.email,
                        "password": values.password,
                        "phone": ""
                    }
                  )
                  .then( response => {
                    //   if( response.status === 201 ) {
                        toast.info( 'Merci, vous êtes desormais inscris en tant que membre' , {
                            position: toast.POSITION.TOP_CENTER
                        });
                        actions.setSubmitting(false);
                        AuthContextClass.setAuth(true);
                        LocalStorageInstance.store('authUser', JSON.stringify(response.data));
                    //   }
                  })
                  .catch( err => { actions.setSubmitting(false); console.log(err) });
    }

    forgotPassword = ( {actions, values, ...rest}, AuthContextClass ) => {
        // TODO: implement register method from custom API
        console.log( 'custom forgot password' )
    }

}