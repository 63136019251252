import React, { useContext, useEffect, useState } from 'react'
import AccountTemplate from './AccountTemplate'
import CardItem from '../shared/CardItem'
import { connect } from 'react-redux'
import { AppUserFundsSelector } from '../../stores/selectors'
import { AccountTemplateContext } from '../../context/AccountContext'

function Funds (props) {
  const [filter] = useContext(AccountTemplateContext)
  const [dataFiltered, setDataFiltered] = useState(null)

  useEffect(() => {
    if (filter !== '') {
      let filtered = []
      Object.keys(props.userFunds).filter(funds => {
        if (props.userFunds[funds].isin) {
          props.userFunds[funds].isin
            .toLowerCase()
            .includes(filter.toLowerCase()) &&
            filtered.push(props.userFunds[funds])
        }

        if (props.userFunds[funds].name) {
          props.userFunds[funds].name
            .toLowerCase()
            .includes(filter.toLowerCase()) &&
            filtered.push(props.userFunds[funds])
        }
      })

      // console.log( filtered )
      setDataFiltered([...new Set(filtered)])
    } else {
      setDataFiltered(null)
    }

    return () => {}
  }, [filter])

  return (
    <AccountTemplate title='Mes fonds'>
      <div className='wrap'>
        {dataFiltered === null && props.userFunds
          ? props.userFunds.map((fund, index) => (
              <CardItem
                key={index}
                isin={fund.isin && fund.isin}
                value={fund.best_of ? parseInt(fund.best_of) : parseInt(0)}
                title={fund.name}
              />
            ))
          : dataFiltered.map((fund, index) => (
              <CardItem
                key={index}
                isin={fund.isin && fund.isin}
                value={fund.best_of ? parseInt(fund.best_of) : parseInt(0)}
                title={fund.name}
              />
            ))}
      </div>
    </AccountTemplate>
  )
}

export default connect(state => ({
  userFunds: AppUserFundsSelector(state)
}))(Funds)
