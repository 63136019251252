import React, { useState, useContext } from 'react'
import Content from '../content/Content'
// import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import 'rc-tooltip/assets/bootstrap.css'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import AnchorLink from 'react-anchor-link-smooth-scroll'
// import { ModalInfoManagerContext } from '../../context/ModalInfoManager'
import { AppTextContext } from '../../context/AppTextProvider'
import Newsletter from '../shared/Newsletter'
import * as scroll from 'react-scroll'

const Home = props => {
  const [displayNl, setDisplayNl] = useState(false)
  // const [dataModal, setDataModal]  = useContext( ModalInfoManagerContext )
  const appText = useContext(AppTextContext)

  // const goTosearch = () => props.history.push(`search/`)

  React.useEffect(() => {
    const hash = window.location.hash

    if (hash !== '') {
      document.querySelector(`[href="${hash}"]`).click()
    } else {
      scroll.animateScroll.scrollToTop()
    }

    return () => {}
  }, [])

  const renderArrowBottom = () => {
    return (
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M3.99999 7.99999L7.99999 12'
          stroke='#FFC34C'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M12 8L8 12'
          stroke='#FFC34C'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M8 11.3333V3.33331'
          stroke='#FFC34C'
          strokeWidth='3'
          strokeLinecap='round'
        />
      </svg>
    )
  }

  const bullets = [
    {
      class: 'very_good',
      indic: 'Très bon → à partir de 80',
      text: 'Très bon'
    },
    {
      class: 'good',
      indic: 'Bon → de 60 à 79',
      text: 'Bon'
    },
    {
      class: 'medium',
      indic: 'Moyen → de 40 à 59',
      text: 'Moyen'
    },
    {
      class: 'bad',
      indic: 'Mauvais → de 20 à 39',
      text: 'Mauvais'
    },
    {
      class: 'very_bad',
      indic: 'Très mauvais → moins de 20',
      text: 'Très mauvais'
    }
  ]

  function convertHex (hex, opacity) {
    let hexFinal = hex.replace('#', '')
    const r = parseInt(hexFinal.substring(0, 2), 16)
    const g = parseInt(hexFinal.substring(2, 4), 16)
    const b = parseInt(hexFinal.substring(4, 6), 16)

    const result = 'rgba(' + r + ',' + g + ',' + b + ',' + opacity / 100 + ')'
    return result
  }

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='anchor'>
          <AnchorLink href='#mission_canary' offset='250'>
            {appText.home.canary_mission_titre}
            {renderArrowBottom()}
          </AnchorLink>

          <AnchorLink href='#canary_score' offset='250'>
            {appText.home.le_canary_score_titre}
            {renderArrowBottom()}
          </AnchorLink>

          <AnchorLink href='#première_recherche' offset='250'>
            {appText.home.ma_premiere_recherche_titre}
            {renderArrowBottom()}
          </AnchorLink>

          <h3>{appText.home.baseline_home}</h3>

          {!displayNl && (
            <h5
              style={{ cursor: 'pointer' }}
              onClick={() => setDisplayNl(!displayNl)}
            >
              {appText.home.link_newsletter}
            </h5>
          )}

          {displayNl && <Newsletter style={{ marginBottom: 20 }} />}
        </div>

        <Content
          title={appText.home.canary_mission_titre}
          id='mission_canary'
          content={appText.home.canary_mission_text}
        ></Content>

        <Content
          title={appText.home.le_canary_score_titre}
          id='canary_score'
          content={appText.home.le_canary_score_text}
        >
          <div
            className='bullet_point'
            style={{
              marginTop: 20,
              display: 'none'
            }}
          >
            {bullets.map((bullet, key) => (
              <div key={key} className={bullet.class}>
                {}
                <span /> {bullet.indic}
              </div>
            ))}
          </div>
        </Content>

        <Content
          title={appText.home.ma_premiere_recherche_titre}
          id='première_recherche'
          content={appText.home.ma_premiere_recherche_text}
        ></Content>
      </div>
    </div>
  )
}

export default withRouter(connect()(Home))
