import React, { useContext, useEffect } from "react";
import "./assets/style.scss";
import AppRouter from "./routing/AppRouter";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PopupManagerContext } from "./context/popupManager";
import PopupFundDetails from "./components/shared/PopupFundDetails";
import { withRouter } from "react-router-dom";
import cx from "classnames/bind";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";
import { AuthContext } from "./context/AuthContext";
// import apiService from './conf/api.custom';
import { AppUserSelector } from "./stores/selectors";
import {
  fetchUser,
  fetchUserFunds,
  loadTexts,
  fetchCategories
} from "./stores/actions";
import axios from "axios";
import { Helmet } from "react-helmet";
import { AppLoader } from "./components/utils/Loading";
import ReactGA from "react-ga";

ReactGA.initialize("UA-154901602-1");

const App = props => {
  const [dataPopup, setDisplayPopup] = useContext(PopupManagerContext);
  const [appDataLoading, setAppDataLoading] = React.useState(true);
  const context = useContext(AuthContext);

  const AppBackground = {
    backgroundColor: !props.location.pathname.includes("fund") && "#FFC34C"
  };

  const AppClasses = cx({
    App: true,
    connectedUser: context.isAuth,
    searchPage:
      props.location.pathname.includes("search") ||
      props.location.pathname.includes("fund"),
    isDesktop: !isMobile
  });

  const trackPage = page => {
    ReactGA.set({
      page
    });
    ReactGA.pageview(page);
  };

  useEffect(() => {
    axios
      .all([props.loadTexts(), props.fetchCategories()])
      .then(() => setAppDataLoading(false));

    return () => {};
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      trackPage(props.location.pathname);
    }

    return () => {};
  }, [props.location]);

  // useEffect(() => {
  //   if (context.isAuth) {
  //     axios.all([props.fetchUser()]).then(
  //       axios.spread(user => {
  //         if (user.user && user.user.id) {
  //           props.fetchUserFunds(user.user.id)
  //         }
  //       })
  //     )
  //   }

  //   return () => {}
  // }, [context.isAuth])

  useEffect(() => {
    return () => {};
  }, [props.appText]);

  return !appDataLoading ? (
    <>
      <PopupFundDetails
        dataPopup={dataPopup}
        closePopup={() => setDisplayPopup({ ...dataPopup, display: false })}
      />
      <div className={AppClasses} style={{ ...AppBackground }}>
        <Helmet>
          <title>Faites les bons choix pour votre épargne • Canary</title>
          <link rel="canonical" href="https://lecanary.com" />
          <meta name="robots" content="noindex" />
          <meta
            name="description"
            content="Canary est l'outil qui évalue les fonds d'investissements."
          />
        </Helmet>
        <ToastContainer position="top-center" />
        {!appDataLoading && <AppRouter />}
      </div>{" "}
    </>
  ) : (
    <AppLoader />
  );
};

export default withRouter(
  connect(
    state => ({
      user: AppUserSelector(state)
      // userFunds
    }),
    {
      fetchUser,
      fetchUserFunds,
      loadTexts,
      fetchCategories
    }
  )(App)
);
