import { createSelector } from 'reselect';

export const todosSelector = state => state.todos;

export const todosIsLoadingSelector = createSelector(
    [ todosSelector ],
    todos => todos.isLoading
)

export const todosDataSelector = createSelector(
    [todosSelector],
    todos => todos.data
)

export const todosDataActiveSelector = createSelector(
    [todosSelector],
    todos => {
        let obj = {}
        todos.data && Object.keys(todos.data).filter( key => !todos.data[key].completed && ( obj[key] = todos.data[key] ) )
        return obj
    }
)


export const todosDataCompletedSelector = createSelector(
    [todosSelector],
    todos => { 
        let obj = {}
        todos.data && Object.keys(todos.data).filter( key => todos.data[key].completed && ( obj[key] = todos.data[key] ) )
        return obj
    }
)