import React from "react";
import { Link } from "react-router-dom";
import { AppTextContext } from "../../context/AppTextProvider";
import { AuthContext } from "../../context/AuthContext";
import Newsletter from "./Newsletter";
import { ModalInfoManagerContext } from "../../context/ModalInfoManager";
import ContactForm from "../modal/ContactForm";
import FondationCanary from "../modal/FondationCanary";

function Footer() {
  const appText = React.useContext(AppTextContext);
  // const context = React.useContext(AuthContext)
  const [dataModal, setDataModal] = React.useContext(ModalInfoManagerContext);

  return (
    <footer className="content">
      <Newsletter />

      <Link to="/qui-sommes-nous"> {appText.footer.footer_text_1} </Link>
      <span>
        <Link to="/faq">{`${appText.footer.footer_text_2} - `} </Link>
        <Link to="/cookies">{appText.footer.footer_text_6} </Link>
      </span>
      <Link to="/cgu"> {appText.footer.footer_text_3} </Link>

      <a
        href="#"
        style={{ color: "#004d33", cursor: "pointer" }}
        onClick={() =>
          setDataModal({
            ...dataModal,
            display: true,
            data: {
              headerBackground: "#FFC34C",
              title: `${appText.footer.footer_text_4}`,
              content: ContactForm,
              contentProps: {
                background: "#FFC34C"
              }
            }
          })
        }
      >
        {" "}
        {appText.footer.footer_text_4}{" "}
      </a>

      <a
        href="#"
        style={{ color: "#004d33", cursor: "pointer" }}
        onClick={() =>
          setDataModal({
            ...dataModal,
            display: true,
            data: {
              headerBackground: "#FFC34C",
              title: `${appText.footer.footer_text_5}`,
              content: FondationCanary,
              contentProps: {
                background: "#FFC34C"
              }
            }
          })
        }
      >
        {appText.footer.footer_text_5}{" "}
      </a>

      <em>
        ©Canary — <Link to="/mentions-legales">Mentions légales</Link>
      </em>
    </footer>
  );
}

export default Footer;
