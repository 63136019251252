import React, {  useState, useEffect, useContext } from 'react'
import '../../stores';
import logoYellow from '../../assets/images/logo-black.svg'
import logoWhite from '../../assets/images/logo-black.svg'
import searchSVG from '../../assets/images/search.svg'
// import searchSVGWhite from '../../assets/images/search-white.svg'
// import { Link } from 'react-router-dom'
import cx from 'classnames/bind';
import { withRouter } from 'react-router-dom'
import { PopupManagerContext } from '../../context/popupManager';
import ArrowBack from '../../assets/images/arrow-back-history.svg';
import InputBoxDoneTyping from 'react-input-box-done-typing';
import { StickyHeaderContext } from '../../context/StickyHeaderContext';

function usePrevious(value) {
    const ref = React.useRef();
    
    React.useEffect(() => {
      ref.current = value;
    }, [value]); 
    
    return ref.current;
}

function StickySearch(props) {

    const [dataPopup, setDisplayPopup] = useContext(PopupManagerContext);
    const [inputValue, setInputValue] = useState('') 
    const [isSticky, setIsSticky] = useState(false) 
    const [displayBack, setdisplayBack] = useState(false) 
    const [previousView, setPreviousView] = useState('/') 
    const [dataStickHeader, setDataStickHeader]   = useContext(StickyHeaderContext)
    const inputSearch = React.useRef('');
    // Get the previous value (was passed into hook on last render)
    const prevView = usePrevious(props.location.pathname);
    
    // const goTosearch = () => {
    //     if( props.location.pathname.includes('search') && dataPopup.display ) {
    //         setDisplayPopup({...dataPopup, display:false })
    //     }
    //     else {
    //         props.history.push(`/search`)
    //     }  
    // }

    useEffect(() => {

        const array_data_url = props.location.pathname.split('/');

        if( array_data_url[1] === 'search' && array_data_url[2] === '' ) {
            setPreviousView(prevView)
        }
        
        if(props.location.pathname === '/'  ) {
            setdisplayBack(false)
        } else {
            setdisplayBack( true)  
        }

        return () => {
            
        };
    }, [props.location])

    useEffect(() => {
        if( dataPopup.display  ) {
            setIsSticky(true)
        } else {
            // if(  window.scrollY < 69)
            setIsSticky(false)
        }

        return () => {
            
        };
    }, [dataPopup])

    useEffect(() => {
        
        inputSearch.current.children[0].value = dataStickHeader.inputValue

        return () => {
            
        };

    }, [dataStickHeader])


    React.useEffect(() => {
        
        // inputSearch.current.children[0].value = dataStickHeader.inputValue
        if( dataStickHeader.inputValue !== '' ) {
          setInputValue(dataStickHeader.inputValue) 
          props.history.push(`/search/${dataStickHeader.inputValue}`)
          inputSearch.current.children[0].value = dataStickHeader.inputValue
        }
          
        return () => {
            
        };
    
    }, [dataStickHeader])



    const navigate = () => {
        
        if( dataPopup.display ) {
            setDisplayPopup({...dataPopup, display:false })
            props.history.goBack()
        } else {
            // console.log( window.location.href )
            if( props.location.pathname.includes('fund')  || window.location.href.includes('fund') ) {
                props.history.push('/')
            } else {
                props.history.goBack()
            }
           
        }
    }

    return (
        <div 
        style={{ 
            position: 'fixed',
            zIndex: 9999999,
            
        }}  
        className={cx({
            withPopup: dataPopup.display || props.location.pathname.includes('fund') ,
            search_container: true
            
        })}>
        <div style={{ padding: '0 8px' }} className={cx({
            search_bar: true,
            animated: true,
            unSticked : !isSticky,
            isSticked :  isSticky || props.location.pathname.includes('fund')
        })} >
            <div style={{
                display: 'flex',
                cursor: 'pointer'
            }} onClick={ () => navigate() }>
            {  displayBack && 
            <>
            <span className="back">
                <img style={{width: 35}} src={ArrowBack} alt="back" />
            </span> 
           </>
            }
            <img 
                style={{ width:  15, maxWidth:  15, position: 'relative', left: displayBack ? -11 : 0, cursor: 'pointer' }}  
                src={ dataPopup.display ? logoWhite : logoYellow} 
                alt='logo' 
                onClick={ () => navigate() }
            />
            </div>

            <div className="input_row_fixed" style={{ margin: 0 }}>
                <img src={ dataPopup.display ? searchSVG : searchSVG} alt='search' />
                <div ref={inputSearch}>
                    <InputBoxDoneTyping 
                        className='pl-35'
                        placeholder='Ecrire le nom du fonds ou code ISIN'
                        doneTyping={ value =>  { 
                            if( value.length >= 3 ) {
                                setDataStickHeader({...dataStickHeader, inputValue:value })
                                // props.history.push(`/search/${encodeURI(value)}`)
                            }
                            
                        }}
                        onChange={ value =>  setInputValue(value) }
                        doneTypingInterval={600}
                        defaultValue={dataStickHeader.inputValue}
                        value={dataStickHeader.inputValue}
                    />
                </div>
            </div>
        </div>
        </div>
    )
}


export default withRouter(StickySearch)
