import React, { createContext, useState } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

export const ModalInfoManagerContext = createContext();

export function ModalInfoProvider({ children }) {
  const [data, setData] = useState({
    display: false,
    data: {
      headerBackground: "#FFC34C"
    }
  });

  React.useEffect(() => {
    const targetElement = document.querySelector(".modal_info");
    if (data.display) {
      disableBodyScroll(targetElement);
    } else {
      if (targetElement) {
        enableBodyScroll(targetElement);
      }
    }

    return () => {};
  }, [data]);

  return (
    <ModalInfoManagerContext.Provider value={[data, setData]}>
      {children}
    </ModalInfoManagerContext.Provider>
  );
}
