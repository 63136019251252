import React from "react";
import { AppTextContext } from "../../context/AppTextProvider";

function DetailsNoteContent(props) {
  const appText = React.useContext(AppTextContext);

  return (
    <>
      {props.contentProps.no_content_title &&
        !props.contentProps.no_content_title && (
          <h2>Détaillez moi cette note</h2>
        )}
      <span
        dangerouslySetInnerHTML={{
          __html: appText.fundDetails.frais_gestion_popup_text,
        }}
      />
      <div className="explanation_fees">
        <div className="bareme">
          <span>Plus élevés</span>
          <span>Moins élevés</span>
        </div>
        <div className="euro-display">
          <span>€</span>
          <span>€</span>
          <span>€</span>
          <span className="active">€</span>
        </div>

        <div className="euro-display">
          <span className="">€</span>
          <span>€</span>
          <span className="active">€</span>
          <span>€</span>
        </div>

        <div className="euro-display">
          <span>€</span>
          <span className="active">€</span>
          <span>€</span>
          <span>€</span>
        </div>

        <div className="euro-display">
          <span className="active">€</span>
          <span className="">€</span>
          <span className="">€</span>
          <span>€</span>
        </div>
      </div>
    </>
  );
}

export default DetailsNoteContent;
