import React from 'react'
import { AppTextContext } from '../../context/AppTextProvider'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import cx from 'classnames/bind'
import apiService from '../../conf/api.custom'
import axios from 'axios'

const ComposantInput = ({ field, form: { touched, errors }, ...props }) => {
  //   console.log(props)
  return (
    <div className={cx('form-group', { invalid: errors[field.name] })}>
      {props.type === 'textarea' ? (
        <textarea placeholder={props.label} {...props} {...field}></textarea>
      ) : (
        <input
          placeholder={props.label}
          type={props.type}
          autoComplete='off'
          className='form-control'
          // value={ props.value }
          {...props}
          {...field}
          // onChange={ e => props.passwordchange( e.target.value , field.name) }
        />
      )}
    </div>
  )
}

function ContactForm (props) {
  const appText = React.useContext(AppTextContext)
  const [confirm, setConfirm] = React.useState(false)

  const contactSchema = Yup.object().shape({
    nom: Yup.string().required('Champs obligatoire'),
    email: Yup.string()
      .required('Champs obligatoire')
      .email('Email non valide'),
    objet: Yup.string().required('Champs obligatoire'),
    message: Yup.string().required('Champs obligatoire')
  })

  const submit = (values, action) => {
    // const arrayName = values.nom.split(' ');

    apiService
      .post('contact', values)
      .then(response => {
        setConfirm(true)
        const arrayName = values.nom.split(' ')
        axios({
          method: 'put',
          url: `https://api.sendinblue.com/v3/contacts/${values.email}`,
          headers: {
            'api-key':
              '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'
          },
          data: {
            email: values.email,
            LNAME: arrayName[0] ? arrayName[0] : '',
            FNAME: arrayName[1] ? arrayName[1] : '',
            listIds: [5]
          }
        }).catch(error => {
          if (error.response.data.code === 'document_not_found') {
            axios({
              method: 'post',
              url: 'https://api.sendinblue.com/v3/contacts',
              headers: {
                'api-key':
                  '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'
              },
              data: {
                email: values.email,
                LNAME: arrayName[0] ? arrayName[0] : '',
                FNAME: arrayName[1] ? arrayName[1] : '',
                listIds: [5]
              }
            })
          }
        })
        setTimeout(() => {
          setConfirm(false)
        }, 2000)
      })
      .catch(error => setConfirm(false))
  }

  return (
    <>
      {!confirm ? (
        <>
          <h2>Ecrivez votre message</h2>
          <Formik
            onSubmit={submit}
            initialValues={{
              nom: '',
              email: '',
              objet: '',
              message: ''
            }}
            validationSchema={contactSchema}
          >
            {({ handleSubmit }) => (
              <form
                onSubmit={handleSubmit}
                className='w-100 d-flex flex-column'
              >
                <Field
                  name='nom'
                  type='text'
                  label='Nom'
                  component={ComposantInput}

                  // passwordchange = { (value, name) => onChangePassword(value, name) }
                />
                <Field
                  name='email'
                  type='email'
                  label='Email'
                  component={ComposantInput}

                  // passwordchange = { (value, name) => onChangePassword(value, name) }
                />
                <Field
                  name='objet'
                  type='text'
                  label='Objet'
                  component={ComposantInput}

                  // passwordchange = { (value, name) => onChangePassword(value, name) }
                />

                <Field
                  name='message'
                  type='textarea'
                  label='Votre message'
                  component={ComposantInput}
                />

                <div
                  className='form-group'
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }}
                >
                  <button type='submit'>Envoyer</button>
                </div>
              </form>
            )}
          </Formik>{' '}
        </>
      ) : (
        <h2
          style={{
            color: '#FFC34C',
            fontSize: 35,
            lineHeight: '45px',
            textAlign: 'center'
          }}
        >
          Message envoyé !
        </h2>
      )}
    </>
  )
}

export default ContactForm
