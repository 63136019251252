import apiService from "../../conf/api.custom";
import { toast } from 'react-toastify';

export const REQUEST_USER = 'get user';
export const FETCH_USER = 'fetch user';
export const FETCH_USER_SUCCESS = 'fetch user success';
export const FETCH_USER_FUNDS_SUCCESS = 'fetch user funds success';
export const FETCH_USER_HISTORY_SUCCESS = 'fetch user history success';
export const FETCH_USER_HISTORY_ERROR = 'fetch user history error';
export const FETCH_USER_ERROR= 'fetch user error';
export const FETCH_USER_FUNDS_ERROR= 'fetch user funds error';
export const FETCH_TEXT_SUCCESS= 'FETCH_TEXT_SUCCESS';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';




export const TRY_EDIT_USER = 'TRY_EDIT_USER';


export const requestUser = () => ({
    type: REQUEST_USER,
});

export const fetchUserSucess = user => ({
    type: FETCH_USER_SUCCESS,
    user
});

export const fetchUserFundsSucess = funds => ({
    type: FETCH_USER_FUNDS_SUCCESS,
    funds
});

export const fetchTextSuccess = data => ({
    type: FETCH_TEXT_SUCCESS,
    data
});

export const fetchUserHistorySucess = history => ({
    type: FETCH_USER_HISTORY_SUCCESS,
    history
});

export const fetchCategoriesSuccess = data => ({
    type: FETCH_CATEGORIES_SUCCESS,
    data
});



export const fetchUser = () => dispatch => {
    dispatch( requestUser() );
    return apiService.get('/auth/users/user_by_token').then(
        user => dispatch( fetchUserSucess({ id : user.data }) ),
        error => dispatch( fetchUserError(error.response) )
    )
}

export const fetchUserFunds = ( user_id ) => dispatch => {
    dispatch( requestUser() );
    return apiService.get(`/fonds/followed`).then(
        funds => dispatch( fetchUserFundsSucess(funds.data.data) ),
        error => dispatch( fetchUserFundsError(error.response) )
    )
}

export const fetchUserHisotry = () => dispatch => {
    dispatch( requestUser() );
    return apiService.get(`fonds/seen`).then(
        funds => dispatch( fetchUserHistorySucess( funds.data.data ) ),
        error => dispatch( fetchUserFundsError(error.response) )
    )
}

export const tryEditUser = (data) => dispatch => {
    return apiService.post(`users/edit`,data).then(
        () => {
            toast.success( 'Compte mis à jour' , {
                position: toast.POSITION.TOP_CENTER
            });
            dispatch( fetchUser() )
        },
    )
}

export const loadTexts = () => dispatch => {
    return apiService.get('localize/').then(
        (response) => {
            dispatch(fetchTextSuccess( response.data ))
        },
    )
}

export const fetchCategories = () => dispatch => {
    return apiService.get('categories_can').then(
        (response) => {
            dispatch(fetchCategoriesSuccess( response.data ))
        },
    )
}

export const fetchUserError = error => ({
    type: FETCH_USER_ERROR,
    error
});

export const fetchUserFundsError = error => ({
    type: FETCH_USER_FUNDS_ERROR,
    error
});

