import React, { useContext } from 'react'
import AccountSearchBar from './AccountSearchBar'
import triangle from '../../assets/images/triangle.svg'
import cx from 'classnames/bind'
// import logoWhite from '../../assets/images/logo-white.svg'
// import { Link } from 'react-router-dom'
import { AccountTemplateContext } from '../../context/AccountContext'
import { AuthContext } from '../../context/AuthContext'
// import goBack from '../../assets/images/arrow-back-history.svg'

function AccountTemplate (props) {
  const [context, setSearchFilter] = useContext(AccountTemplateContext)
  const auth = useContext(AuthContext)

  return (
    <div className='account_template'>
      <header className={cx({ no_search: props.search !== undefined })}>
        {props.search === undefined && (
          <div className='search_container' style={{ paddingTop: 10 }}>
            <AccountSearchBar onFilter={value => setSearchFilter(value)} />
          </div>
        )}
        <h1>
          <span>
            {props.title}
            <img src={triangle} alt='down' />
          </span>
          {props.search !== undefined && (
            <span onClick={() => auth.call('logout')} className='logout'>
              Déconnexion
              {/* <Link to='/'><img className="logo" src={logoWhite} alt="home" /></Link> */}
            </span>
          )}
        </h1>
      </header>
      <div className='account_content'>{props.children}</div>
    </div>
  )
}

export default AccountTemplate
