import apiService from "../../../conf/api.custom";

export const REQUEST_FUNDS = 'request funds';
export const FETCH_FUNDS  = 'fetch funds';
export const FETCH_FUNDS_SUCCESS = 'fetch funds success';
export const FETCH_FUNDS_REPLACE = 'fetch funds replace';
export const FUNDS_HAS_NEXT = 'FUNDS_HAS_NEXT';


export const FETCH_FUNDS_NUMBER = 'fetch funds number';
export const FETCH_FUNDS_ERROR= 'fetch funds error';

// ASYNC ACTIONS


export const requestFunds = () => ({
    type: REQUEST_FUNDS,
});

export const fetchFundsSucess = funds => ({
    type: FETCH_FUNDS_SUCCESS,
    funds
});

export const hasNext = value => ({
    type: FUNDS_HAS_NEXT,
    value
});

export const fetchFundsReplace = funds => ({
    type: FETCH_FUNDS_REPLACE,
    funds
});


export const fetchFundsNumber = number => ({
    type: FETCH_FUNDS_NUMBER,
    number
});

export const fetchFundsError = error => ({
    type: FETCH_FUNDS_ERROR,
    error
});

export const resetFunds = () => async dispatch => {
  dispatch( fetchFundsSucess([]) );
};


export const fetchFunds = ( limit, offset ) => async dispatch => {

    dispatch( requestFunds() )
    
    apiService
        .get(`fonds/${limit}&${offset}`)
        .then( response => { dispatch( fetchFundsSucess(response.data) ); dispatch( hasNext( response.data.has_next ) )  }  )
        .catch( error => fetchFundsError(error.data) )
    
};

export const searchFunds = ( params = '', term, offset, save = true, append = false, order = 'desc', limit = 50 ) => async dispatch => {

    dispatch( requestFunds() )

    if(term === '' ) {
        apiService
        .get(`fonds?&limit=${limit}&offset=${offset}&direction=${order}${params}`)
        .then( response => { 
            if( !append ) {
                dispatch( fetchFundsSucess(response.data.data) ) 
            } else {
                dispatch( fetchFundsReplace(response.data.data) ) 
            }
             
            dispatch( fetchFundsNumber(response.data.count) ) 
            dispatch( hasNext( response.data.has_next ) ) 
        }   )
        .catch( error => fetchFundsError(error.data) )
        
    } else {
        apiService
        .get(
            `fonds?query=${encodeURIComponent(term)}&limit=${limit}&offset=${offset}&direction=${order}${params}`
        )
        .then( response => { 
            if( !append ) {
                dispatch( fetchFundsSucess(response.data.data) ) 
            } else {
                dispatch( fetchFundsReplace(response.data.data) ) 
            }
             
            dispatch( fetchFundsNumber(response.data.count) ) 
            dispatch( hasNext( response.data.has_next ) ) 
        })
        .catch( error => fetchFundsError(error.data))
    }
    
};
