import React from "react";
import { AppTextContext } from "../../context/AppTextProvider";

function ExplainNoteContent(props) {
  const appText = React.useContext(AppTextContext);

  const bullets = [
    {
      class: "very_good",
      indic: "→ à partir de 80",
      text: "Très bon",
    },
    {
      class: "good",
      indic: "→ de 60 à 79",
      text: "Bon",
    },
    {
      class: "medium",
      indic: "→ de 40 à 59",
      text: "Moyen",
    },
    {
      class: "bad",
      indic: "→ de 20 à 39",
      text: "Mauvais",
    },
    {
      class: "very_bad",
      indic: "→ moins de 20",
      text: "Très mauvais",
    },
  ];

  function convertHex(hex, opacity) {
    let hexFinal = hex.replace("#", "");
    const r = parseInt(hexFinal.substring(0, 2), 16);
    const g = parseInt(hexFinal.substring(2, 4), 16);
    const b = parseInt(hexFinal.substring(4, 6), 16);

    const result = "rgba(" + r + "," + g + "," + b + "," + opacity / 100 + ")";
    return result;
  }

  return (
    <>
      {props.contentProps.no_content_title &&
        !props.contentProps.no_content_title && (
          <h2>Expliquez moi cette note</h2>
        )}
      <span
        dangerouslySetInnerHTML={{
          __html: appText.fundDetails.explain_note_content,
        }}
      />
      <div className="bullet_point">
        {bullets.map((bullet) => (
          <div className={bullet.class}>
            {props.contentProps.text === bullet.text && (
              <div
                className="hilighted"
                style={{
                  backgroundColor: convertHex(
                    props.contentProps.background,
                    20
                  ),
                }}
              />
            )}
            <span /> {bullet.indic}
          </div>
        ))}
      </div>
    </>
  );
}

export default ExplainNoteContent;
