import FirebaseAuth from "./FirebaseAuth";
import CustomAuth from "./CustomAuth";

export default [
    {
        type: 'firebase',
        model: FirebaseAuth
    },
    {
        type: 'custom',
        model: CustomAuth
    }
]