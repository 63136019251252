import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import store from "./stores";
import { BrowserRouter as Router } from "react-router-dom";
import AuthProvider from "./context/AuthContext";
import HTML5Backend from "react-dnd-html5-backend";
import { DragDropContextProvider } from "react-dnd";
import { PopupProvider } from "./context/popupManager";
import AccountProvider from "./context/AccountContext";
import { ModalInfoProvider } from "./context/ModalInfoManager";
import AppTextProvider from "./context/AppTextProvider";
import * as serviceWorker from "./serviceWorker";
import { StickyHeaderProvider } from "./context/StickyHeaderContext";

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <AuthProvider provider="custom">
        <DragDropContextProvider backend={HTML5Backend}>
          <StickyHeaderProvider>
            <PopupProvider>
              <ModalInfoProvider>
                <AccountProvider>
                  <AppTextProvider lang="fr">
                    <App />
                  </AppTextProvider>
                </AccountProvider>
              </ModalInfoProvider>
            </PopupProvider>
          </StickyHeaderProvider>
        </DragDropContextProvider>
      </AuthProvider>
    </Router>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
